import {
  rentalYield,
  tenPointFivePercentRentalYield,
  tenPointTwentyFivePercentRentalYield,
  tenPercentRentalYield,
} from '../../../config';

export function calculateMonthlyRent(
  homePrice: number,
  minimumDownPayment: number,
): number {
  let requiredRentalYield = rentalYield;
  if (homePrice >= 150000 && homePrice <= 200000) {
    requiredRentalYield = tenPointFivePercentRentalYield;
  } else if (homePrice > 200000 && homePrice < 250000) {
    requiredRentalYield = tenPointTwentyFivePercentRentalYield;
  } else if (homePrice >= 250000 && homePrice < 300000) {
    requiredRentalYield = tenPercentRentalYield;
  }
  return (
    ((homePrice * requiredRentalYield) / 12) *
    (1 - minimumDownPayment / homePrice)
  );
}
